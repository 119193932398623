<template>
  <div class="expande-horizontal column pt-0">
    <v-flex xs12>
      <div class="windows-style expande-horizontal fonte column">
        <transition name="slide-fade">
          <v-list-item class="pa-0 ma-0">
            <v-avatar
              @click="backToList"
              style="cursor: pointer;"
              size="27"
              :color="$theme.primary"
              class="mr-2"
              icon
            >
              <v-icon color="#f2f2f2">mdi-close</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{
                  get_establishment.new
                    ? "Novo estabelecimento"
                    : get_establishment.nome
                }}
              </v-list-item-title>
              <v-list-item-subtitle class="font-mini">
                Gerencie os detalhes abaixo
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <div v-if="get_establishment._id" class="expande-horizontal">
                <v-chip class="ma-2">
                  <v-icon size="21" class="mr-2" :color="$theme.primary">mdi-storefront</v-icon>
                  <span class="fonte-bold" style="font-size: 18pt;"
                    >ID: SCN00{{ get_establishment.id_establishment }}</span
                  >
                </v-chip>
              </div>
            </v-list-item-action>
          </v-list-item>
        </transition>
      </div>
    </v-flex>
    <div
      style="min-height: 80vh; max-height: 80vh; overflow: auto;"
      class="windows-style-content pt-3 expande-horizontal wrap"
    >
      <v-tabs
        v-model="tabs"
        :background-color="$theme.primary"
        dark
        v-if="false"
        class="fonte"
      >
        <v-tab>Info do estabelecimento</v-tab>
        <v-tab v-show="false" @click="listClientSales"
          >Compras do establishment</v-tab
        >
      </v-tabs>
      <v-flex v-show="tabs == 0" xs12>
        <v-form ref="form">
          <div class="expande-horizontal wrap">
            <v-flex xs12 class="px-3">
              <div class="expande-horizontal centraliza">
                <div class="mr-3">
                  <v-icon color="blue">mdi-file-sign</v-icon>
                </div>
                <div class="expande-horizontal column title-item">
                  <span style="font-size: 16pt;" class="fonte-bold">Dados da empresa</span>
                  <span class="fonte grey--text">Informe os dados da empresa</span>
                </div>
              </div>
            </v-flex>
            <v-flex class="px-2" xs12 md6>
              <v-text-field
                v-model="get_establishment.nome"
                filled
                label="Razão Social"
                clearable
                :color="$theme.primary"
                :rules="[v => !!v || 'Preencha este campo']"
                placeholder="ex: estabelecimentos S.A."
              ></v-text-field>
            </v-flex>
            <v-flex class="px-2" xs12 md6>
              <v-text-field
                v-model="get_establishment.nome_fantasia"
                filled
                label="Nome fantasia"
                clearable
                :color="$theme.primary"
                :rules="[v => !!v || 'Preencha este campo']"
                placeholder="ex: estabelecimentos Ltda."
              ></v-text-field>
            </v-flex>
          </div>
          <v-flex class="px-2" xs12>
            <v-text-field
              v-model="get_establishment.email"
              clearable
              filled
              :color="$theme.primary"
              label="Email"
              placeholder="ex: meuemail@gmail.com"
            ></v-text-field>
          </v-flex>

          <div class="expande-horizontal wrap">
            <v-flex class="px-2" xs12 md4>
              <v-text-field
                v-model="get_establishment.cpf"
                label="CNPJ"
                v-mask="['###.###.###-##', '##.###.###/####-##']"
                filled
                clearable
                :color="$theme.primary"
                placeholder="ex: 00.000.000/0000-00"
              ></v-text-field>
            </v-flex>
            <v-flex class="px-2" xs12 md4>
              <v-text-field
                v-model="get_establishment.telefone"
                label="Contato"
                v-mask="['(##) # #### ####']"
                clearable
                filled
                :color="$theme.primary"
                placeholder="ex: (96) 9 8419-6827"
              ></v-text-field>
            </v-flex>
            <v-flex class="px-2" xs12 md4>
              <v-text-field
                v-model="get_establishment.data_nascimento"
                label="Data de Abertura"
                filled
                v-mask="['##/##/####']"
                clearable
                :color="$theme.primary"
                placeholder="ex: 12/12/1984"
              ></v-text-field>
            </v-flex>
            <!-- 
              - Nome da mãe
              - Endereço
            -->
            <v-flex class="px-2" xs12>
              <div class="expande-horizontal wrap">
                <v-flex xs12 class="px-3">
                  <div class="expande-horizontal centraliza">
                    <div class="mr-3">
                      <v-icon color="blue">mdi-file-sign</v-icon>
                    </div>
                    <div class="expande-horizontal column title-item">
                      <span style="font-size: 16pt;" class="fonte-bold">Endereço</span>
                      <span class="fonte grey--text">Informe o endereço da empresa</span>
                    </div>
                  </div>
                </v-flex>
                <v-flex class="px-1" xs12 md9>
                  <v-text-field
                    v-model="get_establishment.address.endereco"
                    label="Endereço"
                    filled
                    clearable
                    :color="$theme.primary"
                    placeholder="ex: Informe o endereço aqui"
                  ></v-text-field>
                </v-flex>
                <v-flex class="px-1" xs12 md3>
                  <v-text-field
                    v-model="get_establishment.address.cep"
                    label="CEP"
                    filled
                    clearable
                    :color="$theme.primary"
                  ></v-text-field>
                </v-flex>
                <v-flex class="px-1" xs12 md4>
                  <v-text-field
                    v-model="get_establishment.address.bairro"
                    label="Bairro"
                    filled
                    clearable
                    :color="$theme.primary"
                  ></v-text-field>
                </v-flex>
                <v-flex class="px-1" xs12 md4>
                  <v-text-field
                    v-model="get_establishment.address.cidade"
                    label="Cidade"
                    filled
                    clearable
                    :color="$theme.primary"
                  ></v-text-field>
                </v-flex>
                <v-flex class="px-1" xs12 md4>
                  <v-text-field
                    v-model="get_establishment.address.estado"
                    label="Estado"
                    filled
                    clearable
                    :color="$theme.primary"
                  ></v-text-field>
                </v-flex>
              </div>
            </v-flex>
          </div>
          <!-- <div class="expande-horizontal">
            <v-flex class="px-2" xs12>
              <v-textarea
                v-model="get_establishment.obs"
                label="Anotações sobre o establishment"
                filled
                clearable
                :color="$theme.primary"
              ></v-textarea>
            </v-flex>
          </div> -->
          <div class="expande-horizontal wrap">
            <v-flex xs12 class="px-3">
              <div class="expande-horizontal centraliza">
                <div class="mr-3">
                  <v-icon color="blue">mdi-file-sign</v-icon>
                </div>
                <div class="expande-horizontal column title-item">
                  <span style="font-size: 16pt;" class="fonte-bold">Documentos</span>
                  <span class="fonte grey--text">Carrega os documentos da empresa abaixo</span>
                </div>
              </div>
            </v-flex>
            <document-files
              :items="[
                {
                  name: 'Ato constitutivo ou Contrato Social',
                  required: true
                },
                {
                  name: 'Cartão CNPJ',
                  required: true
                },
                {
                  name: 'Comprovante de endereço da Empresa',
                  required: true
                }
              ]"
              v-model="get_establishment.documents"
              :basePath="`documents/establishment`"
              @input="valida_form"
            ></document-files>
            <v-flex xs12 class="px-3">
              <div class="expande-horizontal centraliza">
                <div class="mr-3">
                  <v-icon color="blue">mdi-card-account-details-outline</v-icon>
                </div>
                <div class="expande-horizontal column title-item">
                  <span style="font-size: 16pt;" class="fonte-bold">Sócios</span>
                  <span class="fonte grey--text">Carrega os sócios da empresa abaixo</span>
                </div>
              </div>
            </v-flex>
            <div class="expande-horizontal wrap column">
              <span
                class="ma-3"
                v-if="
                  !get_establishment.partners ||
                    get_establishment.partners.length == 0
                "
              >
                Não há sócios cadastrados
              </span>
              <div class="pa-3 expande-horizontal" v-else>
                <v-flex class="pa-6" xs12>
                  <v-list color="transparent">
                    <v-list-item
                      style="margin-bottom: 6px; border-radius: 6px; background-color: #fff;"
                      v-for="(partner, i) in get_establishment.partners"
                      :key="`partner-${i}`"
                    >
                      <v-avatar>
                        <v-icon>mdi-account-circle</v-icon>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ partner.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ partner.cpf }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
              </div>
              <div class="expande-horizontal pa-2">
                <v-btn color="green" dark @click="initPartnerForm"
                  >Cadastrar sócio</v-btn
                >
              </div>
            </div>
            <v-dialog v-model="new_partner_dialog" width="380">
              <v-card class="pa-2">
                <v-flex xs12>
                  <div class="expande-horizontal wrap">
                    <v-flex xs12>
                      <v-list color="transparent">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              Cadastro de Sócio
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              Preencha as informações abaixo
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn icon @click="new_partner_dialog = false">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-flex>
                    <v-divider></v-divider>
                    <v-flex xs12>
                      <div class="expande-horizontal column">
                        <v-text-field
                          label="Nome do sócio"
                          v-model="partner.name"
                          filled
                          clearable
                          :color="$theme.primary"
                          placeholder="ex: João da Silva"
                        >
                        </v-text-field>

                        <v-text-field
                          label="CPF"
                          v-model="partner.cpf"
                          filled
                          clearable
                          :color="$theme.primary"
                          v-mask="['###.###.###-##']"
                          placeholder="ex: ###.###.###-##"
                        ></v-text-field>
                        <document-files
                          key="partners"
                          id="partners"
                          v-model="partner.documents"
                          :basePath="`documents/establishment/partners`"
                          :items="[
                            {
                              name: 'Habilitação ou RG ou CPF',
                              required: true
                            },
                            {
                              name: 'Comprovante de endereço do sócio',
                              required: true
                            }
                          ]"
                          @input="verifyDocumentFiles($event)"
                        ></document-files>
                        <v-btn
                          color="green"
                          dark
                          @click="addPartner"
                          v-if="ready_to_add"
                          class="ma-2"
                          >Finalizar</v-btn
                        >
                      </div>
                    </v-flex>
                  </div>
                </v-flex>
              </v-card>
            </v-dialog>
          </div>
        </v-form>
      </v-flex>
      <!-- Compras do establishment -->
      <v-flex v-show="tabs == 1" xs12>
        <div class="expande-horizontal wrap">
          <h2 class="fonte-bold ml-3">
            O establishment realizou {{ sales.length }} compras.
          </h2>
          <div
            style="min-height: 67vh; max-height: 67vh; overflow: auto;"
            class="expande-horizontal px-3"
            v-if="sales.length > 0"
          >
            <v-flex xs12 md3 class="mr-3" v-for="sale in sales" :key="sale._id">
              <SaleItem :kanban="sale" />
            </v-flex>
          </div>

          <div class="expande-horizontal centraliza" v-else>
            <span class="my-12 fonte"
              >Este establishment ainda não realizou nenhuma compra</span
            >
          </div>
        </div>
      </v-flex>
    </div>

    <div class="expande-horizontal py-1 windows-style-bottom">
      <v-btn large dark @click="valida_form" :color="$theme.primary">
        <span class="fonte"> Salvar </span>
      </v-btn>
      <!-- <v-btn
        v-if="get_establishment._id"
        color="red"
        class="ml-3"
        @click="excluir_establishment_dialog(get_establishment)"
        text
        >Excluir</v-btn
      > -->
    </div>
  </div>
</template>

<script>
import SaleItem from "./SaleItem.vue";
import { mapActions, mapGetters } from "vuex";
import DocumentFiles from "@/apps/2#auth/components/DocumentFiles.vue";

export default {
  components: {
    SaleItem,
    DocumentFiles
  },
  computed: {
    ...mapGetters(["get_establishment", "getLoggedUser"])
  },
  data() {
    return {
      tabs: 0,
      sales: [],
      partner: {
        documents: []
      },
      new_partner_dialog: false,
      ready_to_add: false
    };
  },
  methods: {
    ...mapActions([
      "criar_establishment",
      "atualizar_establishment",
      "fecha_modal_view_establishment",
      "enableLoading",
      "disableLoading"
    ]),
    initPartnerForm() {
      this.new_partner_dialog = true;
      this.partner = {
        documents: []
      };
      this.ready_to_add = false;
    },
    verifyDocumentFiles(docs) {
      if (docs.length == 2) {
        this.ready_to_add = true;
      }
    },
    addPartner() {
      if (this.get_establishment.partners) {
        this.get_establishment.partners.push(this.partner);
      } else {
        this.get_establishment.partners = [this.partner];
      }

      this.new_partner_dialog = false;
      this.ready_to_add = false;
      this.valida_form();
    },
    listClientSales() {
      this.enableLoading();
      this.$run("establishments/list-client-sales", {
        _id: this.get_establishment._id
      }).then(res => {
        this.disableLoading();
        this.sales = res.data;
      });
    },
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_establishment._id
          ? this.atualizar_establishment()
          : this.criar_establishment();
      }
    },
    backToList() {
      this.$store.commit("set_establishment", {});
      this.$forceUpdate();
    }
  }
};
</script>

<style>
  .title-item {
    border-bottom: 1px solid #e3e3e3;
    padding: 6px;
  }
</style>
